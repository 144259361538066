import { Trans, t } from "@lingui/macro";
import Link from "next/link";

interface Props {
  statusCode: number;
}

export default function Page404({ statusCode }: Props) {
  return (
    <>
      <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max min-h-[65vh] mx-auto">
          <main className="sm:flex sm:pt-36">
            <p className="text-4xl font-extrabold text-safety-orange sm:text-5xl">
              404
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  <Trans>Page not found</Trans>
                </h1>
                <p className="mt-1 text-base text-gray-500">
                  <Trans>
                    Please check the URL in the address bar and try again.
                  </Trans>
                </p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link href="/" passHref>
                  <a href="#" className="btn-primary rounded-md">
                    <Trans>Go back home</Trans>
                  </a>
                </Link>
                <a
                  href={`mailto:${t`soporte@rendalomaq.cl`}`}
                  className="btn-secondary rounded-md"
                >
                  <Trans>Contact support</Trans>
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
